<template>
  <div>
    <v-form @submit.prevent="" ref="form" v-model="valid" class="form_container" lazy-validation>
      <v-card class="elevation-0 rounded card-container" elevation="0" max-width="60rem" width="90vw">
        <div class="d-flex align-center head-container mb-5">
          <div class="auth-logo d-flex">
            <logo type="without-text" />
          </div>
          <h3 class="mb-0 ml-2 black-orangcode--text">Sign in with {{ appName }}</h3>
        </div>
        <v-row v-if="App && App.isPublished" class="py-5">
          <v-col cols="12" md="6">

            <div>
              <div class="mb-3 app-logo-container">
                <img v-if="App.logo" :src="App.logo" alt="">
              </div>
              <div class="title_container">
                <p class="title black-orangcode--text">Login</p>
                <span class="subtitle black-orangcode--text">Access the application <a href="#">{{ App.name
                    }}</a></span>
              </div>
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div class="mb-2">
              <button title="Sign In" @click="signinWithGoogle" class="sign-in_ggl mb-2 ">

                <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                    fill="#4285F4" />
                  <path
                    d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                    fill="#34A853" />
                  <path
                    d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                    fill="#FBBC05" />
                  <path
                    d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                    fill="#EA4335" />
                  <path d="M1 1h22v22H1z" fill="none" />
                </svg>

                <span>Sign In with Google</span>
              </button>
              <div class="separator ">
                <hr class="line" />
                <span>Or</span>
                <hr class="line" />
              </div>
            </div>
            <v-text-field label="Email" color="secondary" v-model="authModel.email" prepend-inner-icon="mdi-gmail"
              placeholder="youremail@domain.com" outlined :rules="[
                (v) => !!v || 'Email is required.',
                (v) => /.+@.+\..+/.test(v) || 'Please enter a valid email.',
              ]" class="w-100" />
            <v-text-field label="Password" v-model="authModel.password" type="password" color="secondary"
              prepend-inner-icon="mdi-lock-outline" placeholder="Enter your password"
              :rules="[(v) => !!v || 'Password is required.']" outlined class="w-100" />

            <div class="mb-4">
              <p class="mb-0">
                Before using {{ appName }}, please review our <a  target="_blank" :href="App.privacyPolicyLink || privacyPolicyLink">Privacy Policy</a> and <a target="_blank" :href="App.termOfUseLink ||  termOfUseLink">Terms of
                  Use</a>.
              </p>
            </div>
            <div class="d-flex justify-end login-bottom-container align-center">
              <a class="primary--text mr-5" href="#" @click.prevent="goTo('/identifier/signup')">Create an Account</a>

              <button :disabled="!valid || loading" @click.prevent="signIn" title="Sign In"
                class="sign-in_btn px-4 py-2 rounded-pill" :class="!valid ? 'disable-btn' : 'primary lighten-1'"
                type="submit">
                <span v-show="!loading">Sign In</span>
                <v-btn small v-show="loading" class="elevation-0 w-100 disable-btn primary lighten-1" icons
                  :loading="true"></v-btn>
              </button>
            </div>
          </v-col>
        </v-row>
        <v-row v-else-if="App && !App.isPublished" class="py-5">
          <v-col cols="12" md="6">
            <div>
              <div class="title_container">
                <p class="title black-orangcode--text">403 Forbidden</p>
                <span class="subtitle black-orangcode--text">The application "{{ App.name }}" is not published or
                  validated by OrangCode.</span>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="py-5">
          <v-col cols="12" md="6">
            <div>
              <div class="title_container">
                <p class="title black-orangcode--text">401 Unauthorized</p>
                <span class="subtitle black-orangcode--text">The application requesting authentication could not be
                  identified.</span>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-form>
  </div>
</template>


<script>
import Logo from "@/components/identifier/AuthLogo.vue";
import { login, signinWithGoogle } from "@/api/user";
import { methods } from "@/utils/constant.js";
import Cookies from 'js-cookie';
import { mapGetters, mapActions } from "vuex";
import { redirectWithFilteredQuery } from "@/utils/navigation";

export default {
  components: { Logo },
  data() {
    return {
      valid: false,
      loading: false,
      authModel: {},
      appName: process.env.APP_NAME || 'OrangCode',
      languages: ["English (English)", 'French (Francais)'],
      selectLanguage: "English (English)"
    };
  },
  computed: {
    ...mapGetters(['App']),
    privacyPolicyLink(){
      return process.env.PRIVACY_POLICY_LINK
    },
    termOfUseLink(){
      return process.env.TERM_OF_USE_LINK
    }
  },
  methods: {
    ...mapActions(['fetchApp']),
    goTo(path) {
      const allowedParams = ["client_id", "redirect_uri", "next_link"];
      redirectWithFilteredQuery(path, this.$router, this.$route, allowedParams);
    },
    async initializeApp() {
      try {
        await this.fetchApp({
          clientId: this.$route.query.client_id,
        });
      } catch (error) {
        console.error("Initialization error:", error);
        methods.dispatchError(
          this.$store,
          error?.response?.data?.message || "An error occurred while initializing the application."
        );
      }
    },

    async signIn() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          await login({
            email: this.authModel.email,
            password: this.authModel.password,
          });
          this.$router.push(`/identifier/double-authentification-verification?client_id=${this.$route.query.client_id}&redirect_uri=${this.$route.query.redirect_uri}&next_link=${this.$route.query.next_link}`);
        } catch (error) {
          console.error("Login error:", error);
          methods.dispatchError(
            this.$store,
            error && error.response && error.response.data && error.response.data.message ? error.response.data.message : "Invalid email or password. Please try again."
          );
          await methods.sleep(2000);
        }
        this.loading = false;
      }
    },

    checkAffiliationCode() {
      const urlParams = new URLSearchParams(window.location.search);
      const affiliationCode = urlParams.get('affiliation_code');
      if (affiliationCode) {
        this.setAffiliationCodeCookie(affiliationCode);
      }
    },
    setAffiliationCodeCookie(affiliationCode) {
      Cookies.set('affiliation_code', affiliationCode, { expires: 3 });
    },
    getAffiliationCodeFromCookie() {
      return Cookies.get('affiliation_code');
    },
    async signinWithGoogle() {
      localStorage.setItem("target_app_client_id", this.$route.query.client_id)
      localStorage.setItem("target_app_redirect_uri", this.$route.query.redirect_uri)
      localStorage.setItem("target_app_next_link", this.$route.query.next_link)
      await signinWithGoogle();
    }
  },
  mounted() {
    this.initializeApp()
  }
};
</script>

<style lang="scss" scoped>
.logo {
  width: 9em;
  height: 2rem;
  background: linear-gradient(to top, #00154c, #12376e, #23487f);
  color: #fff;
  border-radius: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  box-shadow: 20px 20px 40px #ededed, -20px -20px 40px #ffffff;
  overflow: hidden;
}

.logo span {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: top 0.5s;
}


.form_container {
  //width: fit-content;
  // height: fit-content;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  gap: 15px;
  // padding: .5rem 1rem;
  padding: .2rem;
  overflow-x: hidden;
  overflow-y: hidden;
  // padding: 50px 40px 20px 40px;
  // background-color: #ffffff;
  // box-shadow: 0px 106px 42px rgba(0, 0, 0, 0.01),
  //   0px 59px 36px rgba(0, 0, 0, 0.05), 0px 26px 26px rgba(0, 0, 0, 0.09),
  //   0px 7px 15px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
  font-family: "Inter", sans-serif;
}



.icon {
  width: 20px;
  position: absolute;
  z-index: 99;
  left: 12px;
  bottom: 9px;
}

.input_label {
  font-size: 0.75rem;
  color: #8b8e98;
  font-weight: 600;
}

.input_field {
  width: auto;
  height: 40px;
  padding: 0 0 0 40px;
  border-radius: 7px;
  outline: none;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
  border: 1px solid transparent;
  box-shadow: 0px 0px 0px 2px #242424;
  background-color: transparent;
}


.sign-in_ggl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #ffffff;
  border-radius: 7px;
  outline: none;
  color: #242424;
  border: 1px solid #e5e5e5;
  /* Réduction de l'ombre portée */
  // filter: drop-shadow(0px 0.5px 0px #efefef) 
  //       drop-shadow(0px 0.5px 0.25px rgba(239, 239, 239, 0.3));
  // filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
}

.sign-in_apl {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #212121;
  border-radius: 7px;
  outline: none;
  color: #ffffff;
  border: 1px solid #e5e5e5;
  filter: drop-shadow(0px 1px 0px #efefef) drop-shadow(0px 1px 0.5px rgba(239, 239, 239, 0.5));
  cursor: pointer;
}

.separator {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #8b8e98;
}

.separator .line {
  display: block;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #e8e8e8;
}

.note {
  font-size: 0.75rem;
  // color: #8b8e98;
  // text-decoration: underline;
  transition: all 300ms;
  padding: .5rem .5rem;
  border-radius: 5px;
}

.note:hover {
  // font-size: 0.85rem;
  background-color: rgb(218, 218, 218);
  // color: #8b8e98;
  // text-decoration: underline;
}
</style>